<template>
  <v-dialog v-model="dialog" scrollable max-width="800px" :fullscreen="$vuetify.display.mobile" class="scroll">
    <v-card :color="$vuetify.theme.current.colors.background">

      <v-card-title class="justify-space-between align-center pa-4 d-flex">
        Pedidos
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>

        <v-data-table :headers="headers" style="background: transparent ;" :items="sales.data"
          @click:row="(event, row) => confirmPayment(row.item.id)">

          <template v-slot:[`item.number`]="{ item }">
            #{{ item.number }}
          </template>

          <template v-slot:[`item.customer`]="{ item }">
            <span v-if="item.customer_id">
              {{ item.customer?.name }}
            </span>
            <span v-if="!item.customer_id">
              Consumidor final
            </span>
          </template>

          <template v-slot:[`item.salesman`]="{ item }">
            <span v-if="item.salesman_id">
              {{ item.salesman?.name }}
            </span>
          </template>
          <template v-slot:[`item.saved_at`]="{ item }">
            {{ $format.dateBrWeek(item.saved_at) }}
          </template>

          <template v-slot:[`item.net_total`]="{ item }">
            R$ {{ $format.decimal(item.net_total) }}
          </template>

          <template v-slot:bottom />

        </v-data-table>



      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <AppBackBtn class="mr-2" @click="dialog = false" />

      </v-card-actions>

    </v-card>
  </v-dialog>


</template>

<script setup>

const headers = [
  { title: 'Nº', value: 'number', sortable: false },
  { title: 'Cliente', value: 'customer', sortable: false },
  { title: 'Vendedor', value: 'salesman', sortable: false },
  { title: 'Data', value: 'saved_at', sortable: false, align: 'end' },
  { title: 'Total', value: 'net_total', sortable: false, align: 'end' },
];



const $loading = useLoading();

const { $http } = useNuxtApp();

const emit = defineEmits(["select"]);

const dialog = ref(false);

const sales = ref({});

const saleFormStore = useSaleFormStore();

const { isBooted } = storeToRefs(saleFormStore)

const open = async () => {

  await getSales();

  dialog.value = true;
};



const getSales = async (page) => {

  $loading.start();

  await $http.index("sale/sale", {
    page: page,
    status: "order",
  }).then((response) => {

    sales.value = response.sales;

    $loading.stop()

  }).catch((error) => $loading.stop());

};

const confirmPayment = (sale_id) => {
  emit('select', sale_id);
  dialog.value = false;
  isBooted.value = true;
};


defineExpose({ open });
</script>

<style scoped>
.scroll {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #e4e4e4 transparent;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cabfbf;
  }
}
</style>
